import Icon from "lib/components/Icons"
import Text from "lib/components/Text"
import Tooltips from "lib/components/Tooltips"
import { FormEvent, forwardRef } from "react"

export interface InputProps {
  inline?: boolean
  label?: string
  placeholder?: string
  onChange?: (object) => void
  onBlur?: (object) => void
  onFocus?: (object) => void
  onKeyDown?: (object) => void
  value?: string | number
  name?: string
  disabled?: boolean
  maxLength?: number
  success?: boolean
  error?: boolean
  message?: string | string[]
  labelTooltips?: React.ReactNode
  type: "text" | "number" | "email" | "tel" | "password"
  labelSize?: "medium" | "large"
  defaultValue?: string
  noBorder?: boolean
  size?: "small" | "medium" | "large"
  maxNumber?: number
  onInvalid?: (event: FormEvent<HTMLInputElement>) => void
  customClass?: string
  autoComplete?: "off" | "on" | "new-password" | "email" | "username"
  labelColor?: "gray" | "dark"
  isBorderBottom?
  withEmailSuffix?: boolean
  emailSuffix?: string
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      inline,
      label,
      placeholder,
      onChange,
      value,
      name,
      labelTooltips,
      type,
      disabled,
      maxLength,
      success,
      error,
      message,
      labelSize = "medium",
      maxNumber,
      defaultValue,
      noBorder = false,
      size = "medium",
      onFocus,
      onBlur,
      onInvalid,
      onKeyDown,
      autoComplete = "off",
      labelColor = "gray",
      isBorderBottom = false,
      customClass,
      withEmailSuffix = false,
      emailSuffix = "",
    }: InputProps,
    inputRef,
  ) => {
    Input.displayName = "Input"

    let borderColor = "border-[#D1D5DB]"
    let borderFocus = "border-gray-400"
    let iconType = ""
    let iconColor = "gray"
    let inlineStyle = "flex-col"
    let labelStyle = "flex flex-row items-center mb-2"
    let borderWidth = "pl-4 border"
    let sizeText = ""
    let labelValue = <div />

    if (inline) {
      inlineStyle = "flex-row items-center"
      labelStyle = "flex flex-row items-center mr-2"
    }

    if (success) {
      iconType = "check"
      iconColor = "green"
    }

    if (error) {
      borderColor = "border-red-500"
      borderFocus = "border-red-500"
      iconType = "error"
      iconColor = "red"
    }

    if (noBorder) {
      borderWidth = "border-0"
    }

    let paddingRight = "pr-4"

    if (iconType) {
      paddingRight = "pr-8"
    }

    switch (size) {
      case "small":
        sizeText = "text-xs"
        break
      case "large":
        sizeText = "text-lg"
        break
      default:
        break
    }

    if (isBorderBottom) {
      borderWidth = "border-b rounded-none pl-0"
    }

    if (label) {
      if (label.includes("*")) {
        labelValue = (
          <div className="flex gap-x-1">
            <Text type={labelSize !== "medium" ? "body" : "small-body"} color={labelColor}>
              {label.replace(" *", "")}
            </Text>
            <Text weight="bold" type={labelSize !== "medium" ? "body" : "small-body"} color="danger">
              *
            </Text>
          </div>
        )
      } else {
        labelValue = (
          <Text type={labelSize !== "medium" ? "body" : "small-body"} color={labelColor}>
            {label}
          </Text>
        )
      }
    }

    return (
      <div className={`flex ${inlineStyle}`}>
        {label && (
          <div className={labelStyle}>
            {labelValue}
            {labelTooltips && (
              <div className="px-2 -mb-3.5">
                <Tooltips name={name} description={labelTooltips} />
              </div>
            )}
          </div>
        )}
        <div className="relative flex-1">
          <input
            className={`${disabled ? "bg-gray-200" : "bg-white"} w-full rounded ${sizeText} ${borderWidth} ${borderColor} p-1.5 ${paddingRight} focus:outline-none focus:${borderFocus} placeholder:text-sm ${customClass}`}
            placeholder={placeholder}
            onChange={onChange}
            onInvalid={onInvalid}
            value={value}
            name={name}
            disabled={disabled}
            type={type}
            maxLength={maxLength}
            ref={inputRef}
            defaultValue={defaultValue}
            max={maxNumber}
            onWheel={(event) => {
              event.currentTarget.blur()
            }}
            autoComplete={autoComplete}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
          />

          {withEmailSuffix && (
            <span className="absolute right-4 top-1/2 -translate-y-1/2 bg-white text-[#959EAD] pointer-events-none">
              {emailSuffix}
            </span>
          )}

          {iconType && (
            <div className="absolute right-2 top-2 cursor-pointer">
              <Icon iconName={iconType} width={24} height={24} color={iconColor} />
            </div>
          )}
        </div>
        {error && (
          <div className="mt-2">
            <Text type="small-body" color="danger">
              {message}
            </Text>
          </div>
        )}
      </div>
    )
  },
)

export default Input
