import { GoogleLogin } from "@react-oauth/google"
import { UserEmailCheckMutationVariables } from "@graphql/auth/userEmailCheck"
import fetcher from "lib/fetcher"
import Button from "lib/components/Button"
import Container from "lib/components/Container"
import Divider from "lib/components/Divider"
import Input from "lib/components/Input"
import Text from "lib/components/Text"
import InputPassword from "lib/components/InputPassword"
import { LanguageContext } from "context/LanguageContext"
import { useAuth } from "lib/auth"
import { useCheckout } from "lib/checkout"
import { gtmEvent } from "lib/gtm"
import { NextSeo } from "next-seo"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext, useEffect, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-toastify"
import url from "url"
import ButtonCloseQiscus from "@components/shared-components/ButtonCloseQiscus"
import useButtonCloseQiscus from "lib/hooks/useButtonCloseQiscus"
import jwtDecode from "jwt-decode"
import { Token } from "@components/Layouts/LayoutWrapper"
import { captureException } from "@sentry/react"
import { isDesktop, isMobile } from "react-device-detect"
import Icon from "lib/components/Icons"
import { containsNumbers, hasLowercase, hasUppercase } from "lib/helpers"
import dynamic from "next/dynamic"

const Image = dynamic(() => import("lib/components/Image"), {
  ssr: false,
})

type ActiveStep = "emailcheck" | "login" | "createpassword"

export async function getStaticProps(ctx) {
  const activeLocale = ctx.locale

  const dataLanguage = await import(
    `../public/locales/${activeLocale.toUpperCase()}/translations.json`
  )

  const descriptionName = `${dataLanguage.login} ${dataLanguage.to_your_account}`
  const metaTitle = `${dataLanguage.login  } | PasarTrainer`

  return {
    props: {
      metaTitle,
      descriptionName,
    },
  }
}

function Login({ metaTitle, descriptionName }) {
  const router = useRouter()

  const {
    r: redirectPath,
    activationToken: activationTokenParam,
    email: emailParam = "",
    classID,
    restrictedPage = true,
    order_id,
    type,
  }: any = router.query
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    setFocus,
    setValue,
    setError,
  } = useForm<{
    username: string
    password: string
    confirm_password: string
    activation_token: string
  }>()
  const { useTranslation: t } = useContext(LanguageContext)
  const { closeQiscusApp, isQiscusHidden } = useButtonCloseQiscus()
  const { handleUserLogin, handleCheckEmail, isLoggedIn, handleUserProfile } = useAuth()
  const { checkoutData, removeCheckoutData } = useCheckout()
  const [loadingButton, setLoadingButton] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<ActiveStep>("emailcheck")
  const { pathname } = url.parse(router.asPath)
  const [countError, setCountError] = useState(0)
  
  const [googleWidth, setGoogleWidth] = useState(350)

  useEffect(() => {
    const updateButtonWidth = () => {
      const viewportWidth = document.documentElement.clientWidth
      setGoogleWidth(Math.min(viewportWidth - 70, 350))
    }

    updateButtonWidth()
    window.addEventListener("resize", updateButtonWidth)

    return () => window.removeEventListener("resize", updateButtonWidth)
  }, [])

  const handleGenerateActivationToken = async (data) => {
    try {
      await fetcher("/api/auth/generate-token", "POST", {
        email: data.username.toLowerCase() || emailParam,
        isForCredential: true,
      })
      setActiveStep("createpassword")
      toast.success(t("your_activation_token_has_been_sent_to_your_email"))
    } catch (err) {
      const errorMessage = err.response.data.message
      toast.error(errorMessage || t("error_message"))
    }
  }

  const handleEmailCheck = async (data) => {
    try {
      const payloadCheckEmail: UserEmailCheckMutationVariables = {
        email: data.username.toLowerCase() || emailParam,
        is_google: false,
      }

      if (classID) {
        payloadCheckEmail.class_id = classID
      }

      let hasPassword = !data.username.includes("@")

      if (!hasPassword) {
        const res = await handleCheckEmail(payloadCheckEmail)
        hasPassword = !!res.credential_exist
      }

      if (hasPassword) {
        setFocus("password")
        setActiveStep("login")
      }

      if (!hasPassword) {
        await handleGenerateActivationToken(data)
      }
      setCountError(0)
    } catch (err) {
      const errorMessage = err.response.data.message
      let message = t("check_email_error")
      if (errorMessage === "Oops, we cannot find your account") {
        message = t("not_found_account")
      }
      toast.error(message || t("check_email_error"))
      setCountError(countError + 1)
    } finally {
      setLoadingButton(false)
    }
  }

  const handleLogin = async (data) => {
    try {
      const res = await handleUserLogin({
        password: data.password,
        username: data.username.toLowerCase() || emailParam,
      })

      if (res) {
        localStorage.setItem("userdata", JSON.stringify(res))
        localStorage.setItem("hs", res.hs)
        localStorage.setItem(
          "qismo-widget",
          JSON.stringify({
            unique_id: res.email,
            display_name: res.first_name,
            extra_fields: JSON.stringify([]),
            user_id: res.email,
            user_name: res.first_name,
          }),
        )
        gtmEvent("ce_login", {
          user_id: res.user_id,
          custom_user_id: res.user_id,
          account_type: res.user_role !== "ptc-user" ? "Company" : "Individual",
          job_title: res.job_title,
          method: "Email",
        })

        setLoadingButton(false)
        // use router reload to get fresh data from server after login
        if (order_id != null) {
          const dataPayload = {
            class_id: checkoutData.class_id,
            order_id,
            payment_method: checkoutData.payment_method,
            coupon_code: checkoutData.coupon_code,
          }
          await fetcher("/api/order/update", "POST", dataPayload)
          await removeCheckoutData()
          toast.success(t("success_create_order"))
          router.replace(`/checkout/finish?type=${type}&orderId=${order_id}`)
        } else if (!res.is_login_before && res.company_id) {
          router.push("/change-password")
          await handleUserProfile()
        } else {
          localStorage.setItem("active_route", "home")

          if (redirectPath?.includes("my-classes")) {
            localStorage.setItem("active_route", "my-classes")
          }

          if (redirectPath?.includes("orders")) {
            localStorage.setItem("active_route", "orders")
          }

          if (redirectPath?.includes("inhouse-proposal")) {
            localStorage.setItem("active_route", "inhouse-proposal")
          }

          window.location.href = redirectPath || "/homepage"
        }
      }
      setCountError(0)
    } catch (err) {
      setCountError(countError + 1)
      setError("password", { message: t("incorrect_credential_error") })
    } finally {
      setLoadingButton(false)
    }
  }

  const handleCreatePassword = async (data) => {
    try {
      const res = await fetcher("/api/auth/create-password", "POST", {
        activationToken: data.activation_token || activationTokenParam,
        email: data.username.toLowerCase() || emailParam,
        password: data.password,
      })

      if (res) {
        await localStorage.setItem("userdata", JSON.stringify(res))
        await localStorage.setItem("hs", res.hs)
        await toast.success(t("registration_success"))
        await handleUserProfile()
        window.location.href = redirectPath || "/homepage"
      }
    } catch (err) {
      let message = t("registration_error")
      if (err.message === "ErrActivationTokenMismatch") {
        message = t("activation_token_mismatch")
      }
      toast.error(message)
    } finally {
      setLoadingButton(false)
    }
  }

  const loginProcess = async (data) => {
    setLoadingButton(true)
    if (activeStep === "emailcheck") {
      await handleEmailCheck(data)
      setValue("password", "")
    }

    if (activeStep === "login") {
      await handleLogin(data)
    }

    if (activeStep === "createpassword") {
      await handleCreatePassword(data)
    }
  }

  const handleToPreviousStep = () => {
    setActiveStep("emailcheck")
  }

  const handleLoginWithGoogle = async (params, redirectPathParams, classIDParams, orderID, typeParams) => {
    try {
      if (classID) {
        await handleCheckEmail({ email: params.username, class_id: classIDParams, is_google: true })
      }
      const userLogin = await handleUserLogin(params)

      if (userLogin) {
        localStorage.setItem("userdata", JSON.stringify(userLogin))
        localStorage.setItem("hs", userLogin.hs)
        localStorage.setItem(
          "qismo-widget",
          JSON.stringify({
            unique_id: userLogin?.email,
            display_name: userLogin?.first_name,
            extra_fields: JSON.stringify([]),
            user_id: userLogin.email,
            user_name: userLogin.first_name,
          }),
        )
        gtmEvent("ce_login", {
          user_id: userLogin.user_id,
          custom_user_id: userLogin.user_id,
          account_type: userLogin.user_role !== "ptc-user" ? "Company" : "Individual",
          job_title: userLogin.job_title,
          method: "Google",
        })
        if (orderID != null) {
          const getCheckout = localStorage.getItem("checkoutData")
          const dataCheckout = JSON.parse(getCheckout)
          const dataPayload = {
            class_id: dataCheckout.class_id,
            order_id: orderID,
            payment_method: dataCheckout.payment_method,
            coupon_code: dataCheckout.coupon_code,
          }
          await fetcher("/api/order/update", "POST", dataPayload)
          await removeCheckoutData()
          toast.success("Success Create Order")
          router.replace(`/checkout/finish?type=${typeParams}&orderId=${orderID}`)
        } else {
          localStorage.setItem("active_route", "home")

          if (redirectPath?.includes("my-classes")) {
            localStorage.setItem("active_route", "my-classes")
          }

          if (redirectPath?.includes("orders")) {
            localStorage.setItem("active_route", "orders")
          }

          if (redirectPath?.includes("inhouse-proposal")) {
            localStorage.setItem("active_route", "inhouse-proposal")
          }

          window.location.href = redirectPathParams || "/homepage"
        }
      }
    } catch (error) {
      let message = error.response?.data.message
      if (error.response?.data.message === "ErrUserNotFound") {
        message = t("not_found_account")
      }
      toast.error(message || "Oops: Account not found")
    }
  }

  useMemo(() => {
    if (typeof window !== "undefined") {
      const userData = localStorage.getItem("userdata")
      if (restrictedPage && userData) {
        router.replace("/homepage")
      }
    }
  }, [restrictedPage])

  useEffect(() => {
    if (activationTokenParam) {
      setActiveStep("createpassword")
      setValue("username", emailParam)
    }
  }, [activationTokenParam])

  useEffect(() => {
    setFocus("username")
  }, [])

  useEffect(() => {
    if (countError === 3) {
      setError("password", {message: t("incorrect_password_error")})
      setTimeout(() => {
        setCountError(0)
      }, 300000)
    } 
  }, [countError, t])

  return (
    <div>
      <style>
        {`
          ${
            isQiscusHidden
              ? "#qismo-widget {display:none} #qiscus-app-button-close { display:none }"
              : ""
          }
        `}
      </style>
      <NextSeo title={metaTitle} description={descriptionName} canonical={`${process.env.NEXT_PUBLIC_URL}${pathname}`} />
      <div className="hidden sm:flex absolute w-2/5 h-screen -mt-8 sm:mt-0">
        <Image 
          src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1731401427/public/login-accent.webp"
          />
      </div>
      <Container>
        <div className="absolute -top-16 w-full h-[300px] sm:hidden">
          <Image 
            src="https://res.cloudinary.com/https-www-pasartrainer-com/image/upload/v1733983807/public/login-accent-mobile.webp"
          />
        </div>
        <form onSubmit={handleSubmit(loginProcess)} autoComplete="off">
          <div className="flex justify-center pl-0 sm:pl-[36rem] items-start sm:items-center h-full mt-4 sm:mt-0 pt-4 sm:pt-24 sm:h-screen-80">
            <div className="px-4 sm:px-0 w-full sm:w-96 relative">
              {activeStep === "emailcheck" && (
                <div className="animate-slide">
                  <div className="flex justify-center mb-6">
                    <div
                      tabIndex={0}
                      role="button"
                      className="block h-inherit"
                      onClick={() => {
                        localStorage.setItem("active_route", "home")
                        router.push("/")
                      }}>
                      <Link href="/" legacyBehavior>
                        <a className="flex items-center h-inherit">
                          <Image src={isDesktop ? "/assets/images/logo-large-primary.png" : "/assets/images/logo-large-secondary.png"} alt="Picture of the author" width={185} height={40} />
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full overflow-hidden border border-gray-300 rounded-lg p-4 bg-white">
                    <div>
                      <Input
                        type="text"
                        label={`${t("email_address_or_username")} *`}
                        name="username"
                        {...register("username", {
                          required: t("email_username_required"),
                        })}
                        error={!!errors.username}
                        message={errors.username && errors.username.message}
                        placeholder={t("enter_your_email_address_username")}
                        defaultValue={emailParam}
                      />
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        loading={loadingButton}
                        disabled={loadingButton || countError >= 3}>
                        {t("next")}
                      </Button>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-2">
                      <Divider customClass="border w-full h-[1px]" />
                      <Text size="small" alignText="center" color="gray" customClass="mx-2 mb-2">
                        {t("or")}
                      </Text>
                      <Divider customClass="border w-full h-[1px]" />
                    </div>
                    <div className="flex justify-center">
                      <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                          try {
                            let redirectPathValue = window.location.pathname
                            const { email } = jwtDecode<Token>(credentialResponse.credential)
                            const url_string = window.location.href
                            const urlValuePath = new URL(url_string)
                            const classIDValue = urlValuePath.searchParams.get("classID")
                            const orderIDValue = urlValuePath.searchParams.get("order_id")
                            const typeProgram = urlValuePath.searchParams.get("type")
                    
                            if (urlValuePath.searchParams.get("r")) {
                              redirectPathValue = urlValuePath.searchParams.get("r")
                            }

                            const params = { username: email, password: credentialResponse.credential, loginType: "google" }
                            await handleLoginWithGoogle(params, redirectPathValue, classIDValue, orderIDValue, typeProgram)
                          } catch (error) {
                            captureException(error.message)
                          }
                        }}
                        onError={() => {
                          toast.error(t("error_message"))
                        }}
                        width={googleWidth}
                      />
                    </div>
                  </div>
                  <div className="mt-4"/>
                  <div className="w-full overflow-hidden border border-gray-300 rounded-lg py-4 bg-white">
                    <div>
                      <Text color="muted" size="small" alignText="center">
                        {t("dont_have_an_account?")}
                        <span className="text-primary">
                          <Link href="/register"> {t("sign_up")}</Link>
                        </span>
                      </Text>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === "login" && (
                <div className="animate-slide">
                  <div className="flex justify-center mb-6">
                    <div
                      tabIndex={0}
                      role="button"
                      className="block h-inherit"
                      onClick={() => {
                        localStorage.setItem("active_route", "home")
                        router.push("/")
                      }}>
                      <Link href="/" legacyBehavior>
                        <a className="flex items-center h-inherit">
                          <Image src="/assets/images/logo-large-primary.png" alt="Picture of the author" width={185} height={40} />
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full overflow-hidden border border-gray-300 rounded-lg p-4 bg-white">
                    <div className="border-b relative">
                      <Input
                        type="text"
                        name="username"
                        label={`${t("email_address_or_username")} *`}
                        {...register("username", {
                          required: t("email_username_required"),
                        })}
                        error={!!errors.username}
                        message={errors.username && errors.username.message}
                        placeholder={t("enter_your_email_address_username")}
                        noBorder
                        customClass="w-[90%]"
                      />
                      <div role="button" tabIndex={0} className="absolute right-0 top-2.5 bg-white cursor-pointer" onClick={() => setActiveStep("emailcheck")}>
                        <Text color="primary" size="small" customClass="mt-6 mr-2 pl-2 pb-2">
                          {t("edit")}
                        </Text>
                      </div>
                    </div>
                    <div className="mt-4">
                      <InputPassword
                        label={`${t("password")} *`}
                        placeholder={t("enter_your_password")}
                        {...register("password", {
                          required: t("password_required"),
                        })}
                        error={!!errors.password}
                        message={errors.password && (errors.password.message as string)}
                      />
                    </div>
                    <div className="mt-2">
                      <Text color="muted" size="small" alignText="right">
                        <span className="text-primary">
                          <Link href={`/forgot-password?email=${watch("username")}`}>
                            {t("forgot_password?")}{" "}
                          </Link>
                        </span>
                      </Text>
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        loading={loadingButton}
                        disabled={loadingButton || countError >= 3}>
                        {t("login")}
                      </Button>
                    </div>
                    <div className="flex flex-row justify-center items-center mt-2">
                      <Divider customClass="border w-full h-[1px]" />
                      <Text size="small" alignText="center" color="gray" customClass="mx-2 mb-2">
                        {t("or")}
                      </Text>
                      <Divider customClass="border w-full h-[1px]" />
                    </div>
                    <div className="flex justify-center">
                      <GoogleLogin
                        onSuccess={async (credentialResponse) => {
                          try {
                            let redirectPathValue = window.location.pathname
                            const { email } = jwtDecode<Token>(credentialResponse.credential)
                            const url_string = window.location.href
                            const urlValuePath = new URL(url_string)
                            const classIDValue = urlValuePath.searchParams.get("classID")
                            const orderIDValue = urlValuePath.searchParams.get("order_id")
                            const typeProgram = urlValuePath.searchParams.get("type")
                    
                            if (urlValuePath.searchParams.get("r")) {
                              redirectPathValue = urlValuePath.searchParams.get("r")
                            }

                            const params = { username: email, password: credentialResponse.credential, loginType: "google" }
                            await handleLoginWithGoogle(params, redirectPathValue, classIDValue, orderIDValue, typeProgram)
                          } catch (error) {
                            captureException(error.message)
                          }
                        }}
                        onError={() => {
                          toast.error(t("error_message"))
                        }}
                        width={googleWidth}
                      />
                    </div>
                  </div>
                  <div className="mt-4"/>
                  <div className="w-full overflow-hidden border border-gray-300 rounded-lg py-4 bg-white">
                    <div>
                      <Text color="muted" size="small" alignText="center">
                        {t("dont_have_an_account?")}
                        <span className="text-primary">
                          <Link href="/register"> {t("sign_up")}</Link>
                        </span>
                      </Text>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === "createpassword" && (
                <div className="animate-slide">
                  <div className="flex justify-center mb-6">
                    <div
                      tabIndex={0}
                      role="button"
                      className="block h-inherit"
                      onClick={() => {
                        localStorage.setItem("active_route", "home")
                        router.push("/")
                      }}>
                      <Link href="/" legacyBehavior>
                        <a className="flex items-center h-inherit">
                          <Image src="/assets/images/logo-large-primary.png" alt="Picture of the author" width={185} height={40} />
                        </a>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full overflow-hidden border border-gray-300 rounded-lg p-4 bg-white">
                    <div className="mt-4">
                      <div className="mt-4">
                        <Text customClass="mb-1" size="ultra-large" color="default" weight="bold">
                          {t("complete_your_registration")}
                        </Text>
                        <Text size="extra-small" color="muted">
                          {t("activation_token_sent_by_email")}
                        </Text>
                      </div>
                    </div>
                    <div className="mt-4">
                      <Text customClass="mb-2" size="small" color="subtitle">
                        {t("email_address_or_username")}
                      </Text>
                      <Input
                        type="text"
                        name="username"
                        {...register("username", {
                          required: t("email_username_required"),
                        })}
                        error={!!errors.username}
                        message={errors.username && errors.username.message}
                        placeholder={t("enter_your_email_address_username")}
                      />
                    </div>
                    <div className="mt-4">
                      <Text customClass="mb-2" size="small" color="subtitle">
                        {t("activation_token")}
                      </Text>
                      <Input
                        type="text"
                        {...register("activation_token", {
                          required: t("activation_token_required"),
                        })}
                        placeholder={t("enter_your_activation_token")}
                        error={!!errors.activation_token}
                        message={errors.activation_token && errors.activation_token.message}
                        defaultValue={activationTokenParam}
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="mt-4">
                      <Text customClass="mb-2" size="small" color="subtitle">
                        {t("password")}
                      </Text>
                      <InputPassword
                        placeholder={t("enter_your_password")}
                        {...register("password", {
                          required: t("password_required"),
                          pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                            message: t("use_8_or_more_characters"),
                          },
                        })}
                        error={!!errors.password}
                      />
                      {(watch("password")?.length > 0) &&
                        <div className="mt-1">
                          <div className="flex gap-x-1 items-center">
                            <Icon iconName={watch("password").length >= 8 ? "check-circle" : "check-close"} width={16} color={watch("password").length >= 8 ? "#2EBFA8" : "red"} />
                            <Text size="small" color="gray">
                              {t("use_8_characters")}
                            </Text>
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <Icon iconName={hasUppercase(watch("password")) ? "check-circle" : "check-close"} width={16} color={hasUppercase(watch("password")) ? "#2EBFA8" : "red"} />
                            <Text size="small" color="gray">
                              {t("use_one_uppercase")}
                            </Text>
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <Icon iconName={hasLowercase(watch("password")) ? "check-circle" : "check-close"} width={16} color={hasLowercase(watch("password")) ? "#2EBFA8" : "red"} />
                            <Text size="small" color="gray">
                              {t("use_one_lowercase")}
                            </Text>
                          </div>
                          <div className="flex gap-x-1 items-center">
                            <Icon iconName={containsNumbers(watch("password")) ? "check-circle" : "check-close"} width={16} color={containsNumbers(watch("password")) ? "#2EBFA8" : "red"} />
                            <Text size="small" color="gray">
                              {t("use_one_number")}
                            </Text>
                          </div>
                        </div>
                      }
                    </div>
                    <div className="mt-4">
                      <Text customClass="mb-2" size="small" color="subtitle">
                        {t("confirm_password")}
                      </Text>
                      <InputPassword
                        placeholder={t("enter_password_confirmation")}
                        {...register("confirm_password", {
                          required: t("confirm_password_required"),
                          validate: (value) =>
                            value === getValues("password") || t("the_passwords_do_not_match"),
                        })}
                        error={!!errors.confirm_password}
                      />
                      {(watch("confirm_password")?.length > 0) &&
                        <div className="mt-1">
                          {watch("confirm_password") !== watch("password") &&
                            <div className="flex gap-x-1 items-center">
                              <Text size="small" color="danger">
                                {t("the_confirmation_passwords_is_match")}
                              </Text>
                            </div>
                          }
                        </div>
                      }
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        type="submit"
                        loading={loadingButton}
                        disabled={loadingButton}>
                        {t("submit")}
                      </Button>
                    </div>
                    <div className="mt-4">
                      <Button
                        variant="text"
                        color="primary"
                        fullWidth
                        type="button"
                        onClick={handleToPreviousStep}>
                        {t("back")}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </Container>
      <ButtonCloseQiscus closeQiscusApp={closeQiscusApp} />
    </div>
  )
}

Login.layout = "no"

export default Login
